import React, { useEffect, useRef, useState } from 'react';
import visacard from '../../assets/Icon/visacard.png';
import mastercard2 from '../../assets/Icon/mastercard-2.png';
import madacard from '../../assets/Icon/madacard.png';
import tamaracard from '../../assets/Icon/tamara.png';
import tabbybadge from '../../assets/Icon/tabby-badge.png';
import Heading7 from '../Font/Heading7';
import Text4 from '../Font/Text4';
import {
  getAvailablePaymentMethods,
  getPaymentMethod,
  getTamaraData,
  placeOrderCOD,
  EligibleBinDiscount,
  getCheckoutInformation,
  getPayfortInformation,
} from '../../services/cart.service';
import { Spinner } from '../helpers/utils/spinner';
import TamaraInstallmentPlanWidget from '../../Pages/TamaraInstallmentPlanWidget';
import TabbyWidget from '../MostSharedComponent/TabbyWidget';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from '../helpers/utils/cardValidator';
import valid from 'card-validator';
import { useSelector } from 'react-redux';
import { loadCartData } from '../../redux/appAction';
import ApplePay from '../ApplePay/ApplePay';
import { impressionEvents } from '../helpers/utils/ga/impressionEvents';
import { useNavigate } from 'react-router-dom';
import { getTamaraValue } from '../helpers/utils/handsakeConfig';
import PayfortForm from './PayfortForm';
import {
  handleCashOnDelivery,
  handleCheckoutcomCC,
  handleFree,
  handlePayfortFortCC,
  handlePayfortInstallments,
  handleTabbyPayment,
  handleTamaraInstalments,
} from '../helpers/checkout/paymentHandler';
import TabbyPaymentMethod from '../Tabby/TabbyPaymentMethod';

const CheckoutPaymentMethods = ({
  t,
  isAppleDevice,
  tamara6MonthStatus,
  tamara3MonthStatus,
  checkVirtualItemsInCart,
  currentLanguageCode,
  setRedirectPageUrl,
  setPending,
  showApplePay,
  pending,
  cartDetail,
  BASE_PAYMENT_REDIRECT_URL,
  ecom,
  custEmailAddress,
  dispatch,
  services,
  currentLang,
  cartData,
  setCartTotalData,
}) => {
  const payfortForm = useRef();
  const [cardIsDisabled, setCardIsDisabled] = useState(true);
  const [note, setNote] = useState('');
  const [card, setCard] = useState({
    cardNumber: '',
    cardHolder: '',
    monthYear: '',
    month: '',
    year: '',
    cvv: '',
  });
  const [cardErrMsg, setCardErrMsg] = useState({
    cardNumber: '',
    cardHolder: '',
    monthYear: '',
    month: '',
    year: '',
    cvv: '',
  });
  const [isError, setIsError] = useState(false);
  const [payfortData, setPayfortData] = useState(null);
  const [preferenceTab, setPreferenceTab] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const { configurationSettingData, availablePaymentMethodData } = useSelector(
    (state) => state.appData
  );
  const loadingData = useSelector((state) => state.commonReducer);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(services.loaderStart());
    dispatch(getAvailablePaymentMethods());
    localStorage.setItem('last-setPaymentMethod', '');
    var radios = document.getElementsByName('paymentType');
    var selected = Array.from(radios).find((radio) => radio.checked);
    if (selected !== undefined) {
      let params = {
        email: custEmailAddress,
        paymentMethod: {
          method: selected.value,
        },
      };
      updatePaymentMethod(params);
    }
    dispatch(services.loaderEnd());
  }, []);

  useEffect(() => {
    let lastDt = localStorage.getItem('last-dt');
    if (lastDt === 'flatrate') {
      lastDt = 'home';
    } else if (lastDt === 'storepickup') {
      lastDt = 'storepickup';
    } else {
      lastDt = '';
    }
    setPreferenceTab(lastDt);
    const lastPayment = localStorage.getItem('last-setPaymentMethod');
    if (lastPayment) {
      reserCardData();
      if (
        lastPayment !== 'meza' &&
        lastPayment !== 'qitaf' &&
        lastPayment !== 'mokafa'
      ) {
        setSelectedPaymentMethod(lastPayment);

        let params = {
          email: custEmailAddress,
          paymentMethod: {
            method: lastPayment,
          },
        };
        updatePaymentMethod(params);
      } else {
      }
    }
  }, []);

  useEffect(() => {
    const inputs = document?.getElementsByClassName('payment__input__check');
    const res = [...inputs]?.filter((item) => item.checked);
    setIsError(res.length < 1 || !selectedPaymentMethod);
  }, [selectedPaymentMethod]);

  useEffect(() => {
    const errorElement = document.querySelector('.invalid__message');
    if (errorElement && document.activeElement.tagName !== 'INPUT') {
      window.scrollTo(0, Position(errorElement));
    }
  }, [cardErrMsg]);

  function Position(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop > 200 ? [currenttop - 200] : [0];
    }
  }

  const reserCardData = () => {
    setCard({
      ...card,
      cardNumber: '',
      cardHolder: '',
      monthYear: '',
      month: '',
      year: '',
      cvv: '',
    });
    setCardErrMsg({
      ...cardErrMsg,
      cardNumber: '',
      cardHolder: '',
      monthYear: '',
      month: '',
      year: '',
      cvv: '',
    });
  };

  // cart total data update when change payment method
  const updatePaymentMethod = async (params) => {
    await getPaymentMethod(params)
      .then((res) => {
        if (res && res?.data && typeof res?.data === 'object') {
          setCartTotalData(res?.data);
        }
      })
      .catch(() => {});
  };

  const onChangePaymentMethod = (e) => {
    setIsError(false);
    reserCardData();
    setSelectedPaymentMethod(e.target.value);
    localStorage.setItem('last-setPaymentMethod', e.target.value);
    if (
      e.target.value !== 'meza' &&
      e.target.value !== 'qitaf' &&
      e.target.value !== 'mokafa'
    ) {
      let params = {
        email: custEmailAddress,
        paymentMethod: {
          method: e.target.value,
        },
      };
      updatePaymentMethod(params);
    }
  };

  const allFeildValidate = (validateFeild, allErrMsg) => {
    let checkValueStatus = [];
    let checkErrStatus = [];

    validateFeild &&
      validateFeild.map((val) => {
        let keyVal = card[val];
        let errVal = cardErrMsg[val];

        allErrMsg = validateForm('', allErrMsg, val, keyVal);
        if (keyVal !== '') {
          checkValueStatus.push('suc');
        }
        if (errVal === '') {
          checkErrStatus.push('err');
        }
      });

    let checkCardStatus = false;
    if (
      checkValueStatus.length === validateFeild.length &&
      checkErrStatus.length === validateFeild.length
    ) {
      checkCardStatus = true;
    }

    let returnData = {
      allErrMsg: allErrMsg,
      checkCardStatus: checkCardStatus,
    };

    return returnData;
  };
  const onClickPayment = async () => {
    try {
      setPending(true);
      switch (selectedPaymentMethod) {
        case 'payfort_fort_cc':
          await handlePayfortFortCC(
            dispatch,
            loadCartData,
            setPayfortData,
            getPayfortInformation,
            cardErrMsg,
            allFeildValidate,
            setCardErrMsg,
            BASE_PAYMENT_REDIRECT_URL,
            selectedPaymentMethod,
            custEmailAddress,
            card,
            payfortForm
          );
          break;
        case 'checkoutcom_card_payment':
          await handleCheckoutcomCC(
            card,
            allFeildValidate,
            cardErrMsg,
            setCardErrMsg,
            selectedPaymentMethod,
            BASE_PAYMENT_REDIRECT_URL,
            getCheckoutInformation,
            dispatch,
            loadCartData
          );
          break;
        case 'payfort_fort_installments':
          await handlePayfortInstallments(
            dispatch,
            card,
            loadCartData,
            selectedPaymentMethod,
            setPayfortData,
            custEmailAddress,
            getPayfortInformation,
            BASE_PAYMENT_REDIRECT_URL,
            payfortForm
          );
          break;
        case 'cashondelivery':
          await handleCashOnDelivery(
            selectedPaymentMethod,
            dispatch,
            placeOrderCOD,
            loadCartData,
            setRedirectPageUrl
          );
          break;
        case 'free':
          await handleFree(
            dispatch,
            selectedPaymentMethod,
            placeOrderCOD,
            loadCartData,
            setRedirectPageUrl
          );
          break;
        case 'tamara_pay_by_instalments':
        case 'tamara_pay_by_instalments_2':
        case 'tamara_pay_by_instalments_4':
        case 'tamara_pay_by_instalments_6':
          await handleTamaraInstalments(
            dispatch,
            custEmailAddress,
            loadCartData,
            getTamaraData,
            selectedPaymentMethod,
            BASE_PAYMENT_REDIRECT_URL
          );
          break;
        case 'tabby_checkout':
        case 'tabby_installments':
          await handleTabbyPayment(
            selectedPaymentMethod,
            services,
            navigate,
            placeOrderCOD,
            dispatch
          );
          break;
        default:
          throw new Error(
            `Unsupported payment method: ${selectedPaymentMethod}`
          );
      }
    } catch (error) {
      impressionEvents.impressionPaymentFail(
        selectedPaymentMethod,
        error?.response?.data?.message
      );
      dispatch(
        services.notifyError({ message: error?.response?.data?.message })
      );
      setPending(false);
    }

    impressionEvents.impressionAddPaymentInfo(
      cartData,
      'Checkout Payment',
      selectedPaymentMethod
    );
  };

  const onChangeCard = async (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (name === 'cardNumber') {
      value = formatCreditCardNumber(value);
    } else if (name === 'monthYear') {
      value = formatExpirationDate(value);
    } else if (name === 'cvc') {
      value = formatCVC(value);
    }

    setCard({ ...card, [name]: value });
    let manageErrMsg = validateForm(event, cardErrMsg, name, value);
    if (name === 'cardNumber') {
      if (!manageErrMsg.cardNumber && card.cardNumber) {
        eligbleBinDiscount(card.cardNumber.replace(/\s/g, ''));
      } else {
        setNote('');
      }
    }

    setCardErrMsg(manageErrMsg);
  };

  const validateForm = (event, newErrObj, name, value) => {
    //A function to validate each input values
    switch (name) {
      case 'cardNumber':
        if (value === '') {
          newErrObj = { ...newErrObj, [name]: t('Card_Number_is_missing') };
        } else {
          let numberValidation = valid.number(value);
          if (
            numberValidation.isPotentiallyValid === true &&
            numberValidation.isValid === true
          ) {
            setCardIsDisabled(false);
            newErrObj = { ...newErrObj, [name]: '', month: '', year: '' };
          } else {
            setCardIsDisabled(true);
            newErrObj = { ...newErrObj, [name]: t('Card_Number_is_invalid') };
          }
        }
        break;
      case 'cardHolder':
        if (value === '') {
          newErrObj = { ...newErrObj, [name]: t('Card_Holder_is_missing') };
        } else {
          let holderValidation = valid.cardholderName(value);
          if (
            holderValidation.isPotentiallyValid === true &&
            holderValidation.isValid === true
          ) {
            newErrObj = { ...newErrObj, [name]: '' };
          } else {
            newErrObj = { ...newErrObj, [name]: t('Card_Holder_is_invalid') };
          }
        }
        break;
      case 'monthYear':
        if (value === '') {
          newErrObj = { ...newErrObj, [name]: t('Date_is_missing') };
        } else {
          let monthValidation = valid.expirationDate(value);
          if (
            monthValidation.isPotentiallyValid === true &&
            monthValidation.isValid === true
          ) {
            newErrObj = {
              ...newErrObj,
              [name]: '',
              month: monthValidation.month,
              year: monthValidation.year,
            };
          } else {
            newErrObj = { ...newErrObj, [name]: t('Date_is_invalid') };
          }
        }
        break;
      // case "year":
      //   if (value === "") {
      //     newErrObj = { ...newErrObj, [name]: "Year is missing" };
      //   } else {
      //     let yearValidation = valid.expirationYear(value);
      //     if (
      //       yearValidation.isPotentiallyValid === true &&
      //       yearValidation.isValid === true
      //     ) {
      //       newErrObj = { ...newErrObj, [name]: "" };
      //     } else {
      //       newErrObj = { ...newErrObj, [name]: "invalid" };
      //     }
      //   }
      //   break;
      case 'cvv':
        if (value === '') {
          newErrObj = { ...newErrObj, [name]: t('CVV_is_missing') };
        } else {
          let cvvValidation = valid.cvv(value);
          if (
            cvvValidation.isPotentiallyValid === true &&
            cvvValidation.isValid === true
          ) {
            newErrObj = { ...newErrObj, [name]: '' };
          } else {
            newErrObj = { ...newErrObj, [name]: t('CVV_is_invalid') };
          }
        }
        break;
      default:
        break;
    }
    return newErrObj;
  };

  const eligbleBinDiscount = async (trimmedValue) => {
    if (trimmedValue) {
      const response = await EligibleBinDiscount(trimmedValue);
      if (response?.isEligible) {
        setNote({ __html: response?.note });
      } else {
        setNote('');
      }
    }
  };

  const handleBlurEvent = (e) => {
    const event = {
      target: {
        name: e.target.name,
        value: e.target.value.trim(),
      },
    };
    onChangeCard(event);
  };

  if (
    !availablePaymentMethodData?.paymentMethods ||
    availablePaymentMethodData?.paymentMethods?.length === 0 ||
    loadingData?.loading
  ) {
    return <Spinner type="dark" />;
  }

  const paymentFilter = (val) => {
    let showPaymentMethod = true;
    if (preferenceTab === 'storepickup' && val.code === 'cashondelivery') {
      showPaymentMethod = false;
    } else if (checkVirtualItemsInCart(cartDetail.items) === true) {
      if (
        val.code === 'tamara_pay_by_instalments' ||
        val.code === 'tamara_pay_by_instalments_6' ||
        val.code === 'tamara_pay_by_instalments_4'
      ) {
        showPaymentMethod = false;
      }
    }
    return showPaymentMethod;
  };

  const updatedPaymentMethods = availablePaymentMethodData?.paymentMethods
    .filter((val) =>
      cartDetail?.totals_data?.base_grand_total <= 0
        ? val?.code === 'free' && val?.is_available
        : val.code !== 'mestores_applepay' && val?.is_available === true
    )
    .filter(paymentFilter);

  return (
    <>
      {updatedPaymentMethods.map((payment, paymentIndex) => {
        return (
          <React.Fragment key={paymentIndex}>
            {payment.code === 'payfort_fort_cc' ? (
              <div className="payment__form__main__block">
                <label
                  key={payment.code}
                  className="payment__form__block"
                  htmlFor={`payment_with_${payment.code}`}
                >
                  <input
                    id={`payment_with_${payment.code}`}
                    type="radio"
                    className="payment__input__check"
                    name="paymentType"
                    checked={
                      selectedPaymentMethod === payment.code ? 'checked' : ''
                    }
                    value={payment.code}
                    onChange={onChangePaymentMethod}
                  />
                  <div className="payment__selection__text d-flex justify-content-between w-100">
                    <Text4
                      text={
                        currentLang === 'en'
                          ? payment.english_name
                          : payment.arabic_name
                      }
                    />
                    <div className="card__block">
                      <img src={visacard} className="card" />
                      <img src={mastercard2} className="card" />
                      <img src={madacard} className="card" />
                    </div>
                  </div>
                </label>
                {selectedPaymentMethod === payment.code ? (
                  <div className="payment__detail__form__block">
                    {selectedPaymentMethod === 'payfort_fort_cc' ? (
                      <div className="address__content__block">
                        <div className="payment__card__block">
                          <div className="row payment__form__field__row">
                            <div className="col-sm-12 col-md-3 main__form__field__block">
                              {/* <p className="form__label">First Name</p> */}
                              <Heading7
                                text={t('Credit_Card_Number')}
                                marginBottom={10}
                              />
                              <div className="field__block">
                                <input
                                  type="text"
                                  placeholder="xxxx-xxxx-xxxx-xxxx"
                                  className="form__field sentry-mask"
                                  id="name"
                                  name="cardNumber"
                                  value={card.cardNumber}
                                  onChange={(e) => onChangeCard(e)}
                                />
                              </div>
                              {cardErrMsg.cardNumber && (
                                <p className="invalid__message">
                                  {cardErrMsg.cardNumber}
                                </p>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-3 main__form__field__block">
                              {/* <p className="form__label">Mobile Number</p> */}
                              <Heading7
                                text={t('Card_Holder_Name')}
                                marginBottom={10}
                              />
                              <div className="field__block">
                                <input
                                  type="text"
                                  placeholder={t('Card_Holder_Name')}
                                  className="form__field card__holder__field sentry-mask"
                                  id="cardHolder"
                                  name="cardHolder"
                                  value={card.cardHolder}
                                  onChange={(e) => onChangeCard(e)}
                                  onBlur={handleBlurEvent}
                                />
                              </div>
                              {cardErrMsg.cardHolder && (
                                <p className="invalid__message">
                                  {cardErrMsg.cardHolder}
                                </p>
                              )}
                            </div>

                            <div className="col-sm-12 col-md-3 main__form__field__block ">
                              {/* <p className="form__label">First Name</p> */}
                              <Heading7
                                text={t('Month/Year')}
                                marginBottom={10}
                              />
                              <div className="field__block">
                                <input
                                  type="text"
                                  placeholder={t('Month/Year')}
                                  dateformat="MM/YYYY"
                                  className="form__field sentry-mask"
                                  controls={['date']}
                                  id="monthYear"
                                  name="monthYear"
                                  value={card.monthYear}
                                  onChange={(e) => onChangeCard(e)}
                                  disabled={cardIsDisabled}
                                />
                              </div>
                              {cardErrMsg.monthYear && (
                                <p className="invalid__message">
                                  {cardErrMsg.monthYear}
                                </p>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-3 main__form__field__block">
                              <Heading7 text={t('CVV')} marginBottom={10} />
                              <div className="field__block">
                                <input
                                  type="number"
                                  placeholder={t('CVV')}
                                  className="form__field sentry-mask"
                                  id="cvv"
                                  name="cvv"
                                  value={card.cvv}
                                  onChange={(e) => onChangeCard(e)}
                                  disabled={cardIsDisabled}
                                />
                              </div>
                              {cardErrMsg.cvv && (
                                <p className="invalid__message">
                                  {cardErrMsg.cvv}
                                </p>
                              )}
                            </div>
                            {note && !cardErrMsg?.cardNumber && (
                              <div className="col-sm-12">
                                <p dangerouslySetInnerHTML={note}></p>
                              </div>
                            )}
                          </div>
                          <div className="row payment__form__field__row"></div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : payment.code === 'checkoutcom_card_payment' ? (
              <div className="payment__form__main__block">
                <label
                  key={payment.code}
                  className="payment__form__block"
                  htmlFor={`payment_with_${payment.code}`}
                >
                  <input
                    id={`payment_with_${payment.code}`}
                    type="radio"
                    className="payment__input__check"
                    name="paymentType"
                    checked={
                      selectedPaymentMethod === payment.code ? 'checked' : ''
                    }
                    value={payment.code}
                    onChange={onChangePaymentMethod}
                  />
                  <div className="payment__selection__text d-flex justify-content-between w-100">
                    <Text4
                      text={
                        currentLang === 'en'
                          ? payment.english_name
                          : payment.arabic_name
                      }
                    />
                    <div className="card__block">
                      <img src={visacard} className="card" />
                      <img src={mastercard2} className="card" />
                      <img src={madacard} className="card" />
                    </div>
                  </div>
                </label>
                {selectedPaymentMethod === payment.code ? (
                  <div className="payment__detail__form__block">
                    {selectedPaymentMethod === 'checkoutcom_card_payment' ? (
                      <div className="address__content__block">
                        <div className="payment__card__block">
                          <div className="row payment__form__field__row">
                            <div className="col-sm-12 col-md-3 main__form__field__block">
                              <Heading7
                                text={t('Credit_Card_Number')}
                                marginBottom={10}
                              />
                              <div className="field__block">
                                <input
                                  type="text"
                                  placeholder="xxxx-xxxx-xxxx-xxxx"
                                  className="form__field sentry-mask"
                                  id="name"
                                  name="cardNumber"
                                  value={card.cardNumber}
                                  onChange={(e) => onChangeCard(e)}
                                />
                              </div>
                              {cardErrMsg.cardNumber && (
                                <p className="invalid__message">
                                  {cardErrMsg.cardNumber}
                                </p>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-3 main__form__field__block">
                              <Heading7
                                text={t('Card_Holder_Name')}
                                marginBottom={10}
                              />
                              <div className="field__block">
                                <input
                                  type="text"
                                  placeholder={t('Card_Holder_Name')}
                                  className="form__field card__holder__field sentry-mask"
                                  id="cardHolder"
                                  name="cardHolder"
                                  value={card.cardHolder}
                                  onChange={(e) => onChangeCard(e)}
                                  onBlur={handleBlurEvent}
                                />
                              </div>
                              {cardErrMsg.cardHolder && (
                                <p className="invalid__message">
                                  {cardErrMsg.cardHolder}
                                </p>
                              )}
                            </div>

                            <div className="col-sm-12 col-md-3 main__form__field__block ">
                              {/* <p className="form__label">First Name</p> */}
                              <Heading7
                                text={t('Month/Year')}
                                marginBottom={10}
                              />
                              <div className="field__block">
                                <input
                                  type="text"
                                  placeholder={t('Month/Year')}
                                  dateformat="MM/YYYY"
                                  className="form__field sentry-mask"
                                  controls={['date']}
                                  id="monthYear"
                                  name="monthYear"
                                  value={card.monthYear}
                                  onChange={(e) => onChangeCard(e)}
                                  disabled={cardIsDisabled}
                                />
                              </div>
                              {cardErrMsg.monthYear && (
                                <p className="invalid__message">
                                  {cardErrMsg.monthYear}
                                </p>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-3 main__form__field__block">
                              {/* <p className="form__label">First Name</p> */}
                              <Heading7 text={t('CVV')} marginBottom={10} />
                              <div className="field__block">
                                <input
                                  type="number"
                                  placeholder={t('CVV')}
                                  className="form__field sentry-mask"
                                  id="cvv"
                                  name="cvv"
                                  value={card.cvv}
                                  onChange={(e) => onChangeCard(e)}
                                  disabled={cardIsDisabled}
                                />
                              </div>
                              {cardErrMsg.cvv && (
                                <p className="invalid__message">
                                  {cardErrMsg.cvv}
                                </p>
                              )}
                            </div>
                            {note && !cardErrMsg?.cardNumber && (
                              <div className="col-sm-12">
                                <p dangerouslySetInnerHTML={note}></p>
                              </div>
                            )}
                          </div>
                          <div className="row payment__form__field__row"></div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : payment.code === 'payfort_fort_installments' ? (
              <label
                key={payment.code}
                className="payment__form__block"
                htmlFor={`payment_with_${payment.code}`}
              >
                <input
                  id={`payment_with_${payment.code}`}
                  type="radio"
                  className="payment__input__check"
                  name="paymentType"
                  value={payment.code}
                  checked={
                    selectedPaymentMethod === payment.code ? 'checked' : ''
                  }
                  onChange={onChangePaymentMethod}
                />
                <div className="payment__selection__text">
                  <Text4
                    text={
                      currentLang === 'en'
                        ? payment.english_name
                        : payment.arabic_name
                    }
                  />
                </div>
              </label>
            ) : payment.code === 'tamara_pay_by_instalments' ? (
              <>
                {tamara3MonthStatus === true && (
                  <div className="payment__form__main__block">
                    <label
                      key={payment.code}
                      className="payment__form__block"
                      htmlFor={`payment_with_${payment.code}`}
                    >
                      <input
                        id={`payment_with_${payment.code}`}
                        type="radio"
                        className="payment__input__check"
                        name="paymentType"
                        checked={
                          selectedPaymentMethod === payment.code
                            ? 'checked'
                            : ''
                        }
                        value={payment.code}
                        onChange={onChangePaymentMethod}
                      />
                      <div className="payment__selection__text d-flex justify-content-between w-100">
                        <Text4
                          text={
                            currentLang === 'en'
                              ? payment.english_name
                              : payment.arabic_name
                          }
                        />

                        <div className="card__block tamara__logobox">
                          <img src={tamaracard} className="card" />
                        </div>
                      </div>
                    </label>
                    {selectedPaymentMethod === payment.code ? (
                      <div className="payment__detail__form__block tamara__paybox">
                        {selectedPaymentMethod ===
                        'tamara_pay_by_instalments' ? (
                          <TamaraInstallmentPlanWidget
                            months={3}
                            price={cartDetail?.totals_data?.base_grand_total}
                            currency={
                              cartDetail?.totals_data?.base_currency_code
                            }
                            minLimit={getTamaraValue('tamara3_min')}
                            maxLimit={getTamaraValue('tamara3_max')}
                            currentLanguageCode={currentLanguageCode}
                            color="#dc3a1a"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </>
            ) : payment.code === 'tamara_pay_by_instalments_4' ? (
              <>
                <div className="payment__form__main__block">
                  <label
                    key={payment.code}
                    className="payment__form__block"
                    htmlFor={`payment_with_${payment.code}`}
                  >
                    <input
                      id={`payment_with_${payment.code}`}
                      type="radio"
                      className="payment__input__check"
                      name="paymentType"
                      checked={
                        selectedPaymentMethod === payment.code ? 'checked' : ''
                      }
                      value={payment.code}
                      onChange={onChangePaymentMethod}
                    />
                    <div className="payment__selection__text d-flex justify-content-between w-100">
                      <Text4
                        text={
                          currentLang === 'en'
                            ? payment.english_name
                            : payment.arabic_name
                        }
                      />

                      <div className="card__block tamara__logobox">
                        <img src={tamaracard} className="card" />
                      </div>
                    </div>
                  </label>
                  {selectedPaymentMethod === payment.code ? (
                    <div className="payment__detail__form__block tamara__paybox">
                      {selectedPaymentMethod ===
                      'tamara_pay_by_instalments_4' ? (
                        <TamaraInstallmentPlanWidget
                          months={4}
                          price={cartDetail?.totals_data?.base_grand_total}
                          currency={cartDetail?.totals_data?.base_currency_code}
                          minLimit={getTamaraValue('tamara3_min')}
                          maxLimit={getTamaraValue('tamara3_max')}
                          currentLanguageCode={currentLanguageCode}
                          color="#dc3a1a"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : payment.code === 'tamara_pay_by_instalments_6' ? (
              <>
                {tamara6MonthStatus === true && (
                  <div className="payment__form__main__block">
                    <label
                      key={payment.code}
                      className="payment__form__block"
                      htmlFor={`payment_with_${payment.code}`}
                    >
                      <input
                        id={`payment_with_${payment.code}`}
                        type="radio"
                        className="payment__input__check"
                        name="paymentType"
                        checked={
                          selectedPaymentMethod === payment.code
                            ? 'checked'
                            : ''
                        }
                        value={payment.code}
                        onChange={onChangePaymentMethod}
                      />
                      <div className="payment__selection__text d-flex justify-content-between w-100">
                        <Text4
                          text={
                            currentLang === 'en'
                              ? payment.english_name
                              : payment.arabic_name
                          }
                        />

                        <div className="card__block tamara__logobox">
                          <img src={tamaracard} className="card" />
                        </div>
                      </div>
                    </label>
                    {selectedPaymentMethod === payment.code ? (
                      <div className="payment__detail__form__block tamara__paybox">
                        {selectedPaymentMethod ===
                        'tamara_pay_by_instalments_6' ? (
                          <TamaraInstallmentPlanWidget
                            months={6}
                            price={cartDetail?.totals_data?.base_grand_total}
                            currency={
                              cartDetail?.totals_data?.base_currency_code
                            }
                            minLimit={getTamaraValue('tamara6_min')}
                            maxLimit={getTamaraValue('tamara6_max')}
                            currentLanguageCode={currentLanguageCode}
                            color="#dc3a1a"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </>
            ) : payment.code === 'tabby_checkout' ? (
              <div className="payment__form__main__block">
                <label
                  key={payment.code}
                  className="payment__form__block"
                  htmlFor={`payment_with_${payment.code}`}
                >
                  <input
                    id={`payment_with_${payment.code}`}
                    type="radio"
                    className="payment__input__check"
                    name="paymentType"
                    checked={
                      selectedPaymentMethod === payment.code ? 'checked' : ''
                    }
                    value={payment.code}
                    onChange={onChangePaymentMethod}
                  />
                  <div className="payment__selection__text d-flex justify-content-between w-100">
                    <Text4
                      text={
                        currentLang === 'en'
                          ? payment.english_name
                          : payment.arabic_name
                      }
                    />

                    <div className="card__block tamara__logobox">
                      <img src={tabbybadge} className="card" />
                    </div>
                  </div>
                </label>
                {selectedPaymentMethod === payment.code ? (
                  <div className="payment__detail__form__block tamara__paybox">
                    {selectedPaymentMethod === 'tabby_checkout' && (
                      <TabbyWidget
                        price={cartDetail?.totals_data?.base_grand_total}
                        currency={cartDetail?.totals_data?.base_currency_code}
                        widgetType="TabbyCard"
                        currentLanguageCode={currentLanguageCode}
                      />
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : payment.code === 'tabby_installments' ? (
              <div className="payment__form__main__block">
                <label
                  key={payment.code}
                  className="payment__form__block"
                  htmlFor={`payment_with_${payment.code}`}
                >
                  <input
                    id={`payment_with_${payment.code}`}
                    type="radio"
                    className="payment__input__check"
                    name="paymentType"
                    checked={
                      selectedPaymentMethod === payment.code ? 'checked' : ''
                    }
                    value={payment.code}
                    onChange={onChangePaymentMethod}
                  />
                  <div className="payment__selection__text d-flex justify-content-between w-100">
                    <Text4
                      text={
                        currentLang === 'en'
                          ? payment.english_name
                          : payment.arabic_name
                      }
                    />

                    <div className="card__block tamara__logobox">
                      <img src={tabbybadge} className="card" />
                    </div>
                  </div>
                </label>
                {selectedPaymentMethod === payment.code ? (
                  <div className="payment__detail__form__block tamara__paybox">
                    {selectedPaymentMethod === 'tabby_installments' && (
                      <TabbyPaymentMethod t={t} price={cartDetail?.totals_data?.base_grand_total} currentLang={currentLanguageCode} />
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <label
                key={payment.code}
                className="payment__form__block"
                htmlFor={`payment_with_${payment.code}`}
              >
                <input
                  id={`payment_with_${payment.code}`}
                  type="radio"
                  className="payment__input__check"
                  name="paymentType"
                  checked={
                    selectedPaymentMethod === payment.code ? 'checked' : ''
                  }
                  value={payment.code}
                  onChange={onChangePaymentMethod}
                />
                <div className="payment__selection__text">
                  <Text4
                    text={
                      currentLang === 'en'
                        ? payment.english_name
                        : payment.arabic_name
                    }
                  />
                </div>
              </label>
            )}
          </React.Fragment>
        );
      })}

      {payfortData && (
        <PayfortForm payfortData={payfortData} ref={payfortForm} />
      )}

      <div className="continue__button__block row mt-5">
        {isAppleDevice &&
          (Number(configurationSettingData?.applePay) ||
            ecom?.featureFlags?.ecom?.bootstraper?.apple_pay_checkout) && (
            <div className="col-6">
              {!showApplePay && (
                <ApplePay
                  paymentMethod={Number(configurationSettingData?.applePay) ? 'mestores_applepay' : 'checkoutcom_apple_pay'}
                  checkout
                  t={t}
                  total={cartData?.totals_data?.base_grand_total}
                />
              )}
            </div>
          )}
        <div className={isAppleDevice ? 'col-6' : 'col-12'}>
          <button
            onClick={onClickPayment}
            className=" btn btn__primary__orange w-100"
            disabled={
              (availablePaymentMethodData?.paymentMethods &&
                availablePaymentMethodData?.paymentMethods?.length === 0) ||
              pending === true
                ? true
                : false || isError
            }
          >
            {pending === true ? <Spinner /> : t('makeThePayment')}
          </button>
        </div>
      </div>
    </>
  );
};

export default CheckoutPaymentMethods;
