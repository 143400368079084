import React, { useEffect, useState } from "react";
import Heading6 from "../Components/Font/Heading6";
import Price from "../Components/Font/Price";
// import mezzaimg from './../assets/Icon/mezzareward.png';
import closeicon from './../assets/Icon/cancel_grey.svg';
import { useDispatch, useSelector } from "react-redux";
import apiHelper from "../Components/helpers/utils/apiHelper";
import * as services from "./../services/services";
import LoyaltyPointBlock from "../Components/MostSharedComponent/LoyaltyPointBlock";
import mezzacard from './../assets/Icon/mezzacard.png';
import { getLoyaltyEarnTypesData, loadCartData } from "../redux/appAction";
import { useTranslation } from "react-i18next";
import { getAvailablePaymentMethods } from "../services/cart.service";
import { titleChange } from "../Components/helpers/utils/titleNameChange";
import OrderItems from "../Components/MostSharedComponent/OrderItems";
import NewLoader from "../Components/Common/newloader";
import { Spinner } from "../Components/helpers/utils/spinner";
import { impressionEvents } from "../Components/helpers/utils/ga/impressionEvents";
import TamaraInstallmentPlanWidget from "../Pages/TamaraInstallmentPlanWidget";
import TabbyWidget from "../Components/MostSharedComponent/TabbyWidget";
import TabbyBanner from "../Components/Tabby/TabbyBanner";
import TabbyInformation from "../Components/Tabby/TabbyInformation";
const niqaty = require("./../assets/Icon/niqaty.png")

function Order_Summary_New({ cartDetail, cartTotalData, loyaltyEarnType, loyaltyEarnTypeId, loyaltyRadioName }) {

    const [isBundleExist, setIsBundleExist] = useState(false);
    const [pending, setPending] = useState(false);

    const [coupenLoading, setCoupenLoading] = useState(false);
    const [programLoading, setProgramLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isTamaraEnabled4, setIsTamaraEnabled4] = useState(false)
    const [tabbyEnabled, setTabbyEnabled] = useState(false)
    const [showTabby, setShowTabby] = useState(false);

    
    const dispatch = useDispatch();
    const cartData = useSelector((state) => state.appData.cartData);
    const { t } = useTranslation();
    const meza = cartDetail && cartDetail?.totals_data.total_segments.find((item) => item.code === 'mestores_points');
    const qitaf = cartDetail && cartDetail?.totals_data.total_segments.find((item) => item.code === 'mestores_qitaf');
    const codFee = cartTotalData && cartTotalData?.total_segments.find((item) => item.code === 'cash_on_delivery_fee');
    const subTotalData = cartTotalData && cartTotalData?.total_segments.find((item) => item.code === 'subtotal');
    const taxData = cartTotalData && cartTotalData?.total_segments.find((item) => item.code === 'tax');
    const mokafa = cartTotalData && cartTotalData?.total_segments.find((item) => item.code === 'mestores_alrajhi');
    const niqatyLoyalty = cartDetail && cartDetail?.totals_data.total_segments.find((item) => item.code === 'mestores_niqaty');

    useEffect(() => {
        if (Object.values(cartDetail).length !== 0) {
          if(cartDetail?.items){
            if (cartDetail.conflict_status === 1) {
                setIsBundleExist(true);
            } else {
                setIsBundleExist(false);

            }
            // console.log('data', data);
          }
          window.scrollTo(0, 0);
        }
        dispatch(getAvailablePaymentMethods()).then((item) => {
            setIsTamaraEnabled4(item?.paymentMethods.find((i) => i.code === "tamara_pay_by_instalments_4" && i.is_available) !== undefined ? true : false)
            setTabbyEnabled(item?.paymentMethods.find((i) => i.code === "tabby_installments" && i.is_available) !== undefined ? true : false)
        });
    }, [cartDetail]);

    const removeLoyaltyHandler = async (program) => {
        let params = {
            program: program
        }
        setProgramLoading(true);
        try {
            let response = await apiHelper(`/V1/cart/loyalty-points`, 'delete', params, {});
            dispatch(loadCartData());
            if (response?.data?.status === true) {
                dispatch(services.notifySuccess({ message: t("Succesfully removed loyalty points") }))
            } else {
                dispatch(services.notifyError({ message: t("Error occured when cancelling loyalty points") }))
            }
            setProgramLoading(false);
        } catch (error) {
            dispatch(services.notifyError({ message: t(error.response.data.message) }));
            setProgramLoading(false);
        }
    };


    const [coupenCode, setCoupenCode] = useState("")
    const handleChange = (e) => {
        setCoupenCode(e.target.value)
    }
    const handleApplyCode = async () => {
        if (coupenCode !== "") {
            setCoupenLoading(true);
            let params = {
                couponCode: coupenCode.trim(),
            };
            try {
                let response = await apiHelper(`/V1/cart/coupon-code`, 'put', params, {});  
                impressionEvents.impressionCouponApply(
                    coupenCode,
                    "Coupon code is applied"
                );
                dispatch(services.notifySuccess({ message: t("Coupon code is applied") }));
                // console.log(response);
                dispatch(loadCartData());
                dispatch(getAvailablePaymentMethods()).then((item) => {
                    setIsTamaraEnabled4(item?.paymentMethods.find((i) => i.code === "tamara_pay_by_instalments_4" && i.is_available) !== undefined ? true : false)
                    setTabbyEnabled(item?.paymentMethods.find((i) => i.code === "tabby_installments" && i.is_available) !== undefined ? true : false)
                });
                setCoupenCode('');
                setCoupenLoading(false);
            } catch (error) {
                // console.log("error ", error);
                
                impressionEvents.impressionCouponApply(
                    coupenCode, t(error?.response?.data?.message)
                );
                dispatch(services.notifyError({ message: t(error?.response?.data?.message) }));
                dispatch(loadCartData());
                dispatch(getAvailablePaymentMethods()).then((item) => {
                    setIsTamaraEnabled4(item?.paymentMethods.find((i) => i.code === "tamara_pay_by_instalments_4" && i.is_available) !== undefined ? true : false)
                    setTabbyEnabled(item?.paymentMethods.find((i) => i.code === "tabby_installments" && i.is_available) !== undefined ? true : false)
                });
                setCoupenLoading(false);
            }
        }
    }

    const removeCouponCode = async () => {
        setLoading(true);
        try {
            let response = await apiHelper(
                `/V1/cart/coupon-code`,
                "delete",
                {},
                {}
            );
            dispatch(
                services.notifySuccess({ message: t("Remove successfully") })
            );
            setLoading(false);
            dispatch(loadCartData());
            dispatch(getLoyaltyEarnTypesData());
            dispatch(getAvailablePaymentMethods()).then((item) => {
                setIsTamaraEnabled4(item?.paymentMethods.find((i) => i.code === "tamara_pay_by_instalments_4" && i.is_available) !== undefined ? true : false)
                setTabbyEnabled(item?.paymentMethods.find((i) => i.code === "tabby_installments" && i.is_available) !== undefined ? true : false)
            });
        } catch (error) {
            setLoading(false);
            // console.log("error ", error);
            dispatch(
                services.notifyError({ message: t(error?.response?.data?.message) })
            );
            dispatch(loadCartData());
            dispatch(getLoyaltyEarnTypesData());
            dispatch(getAvailablePaymentMethods()).then((item) => {
                setIsTamaraEnabled4(item?.paymentMethods.find((i) => i.code === "tamara_pay_by_instalments_4" && i.is_available) !== undefined ? true : false)
                setTabbyEnabled(item?.paymentMethods.find((i) => i.code === "tabby_installments" && i.is_available) !== undefined ? true : false)
            });
        }
    };

    const removeMokafa = async () => {
        setProgramLoading(true);
        try {
            let response = await apiHelper(`/V1/cart/mokaffat/cancel`, 'post', {}, {});
            dispatch(services.notifySuccess({ message: t("Removed applied discount") }));
            dispatch(loadCartData());
            dispatch(getAvailablePaymentMethods()).then((item) => {
                setIsTamaraEnabled4(item?.paymentMethods.find((i) => i.code === "tamara_pay_by_instalments_4" && i.is_available) !== undefined ? true : false)
                setTabbyEnabled(item?.paymentMethods.find((i) => i.code === "tabby_installments" && i.is_available) !== undefined ? true : false)
            });
            setProgramLoading(false);
        } catch (error) {
            setProgramLoading(false);
            dispatch(services.notifyError({ message: t("Something went wrong, please try again later.") }));
        }
    };

    const removeNiqaty = async () => {
        setProgramLoading(true);
        try {
            await apiHelper(
                '/V1/cart/niqaty/cancel',
                "delete",
                {},
                {}
            );
            dispatch(services.notifySuccess({ message: t("Removed applied discount") }));
            dispatch(loadCartData());
            dispatch(getLoyaltyEarnTypesData());
        } catch (error) {
            const errorMessage = error?.response?.data?.message_localized ? t(error?.response?.data?.message_localized) : error?.response?.data?.message ? error?.response?.data?.message : t('Something went wrong.');
            dispatch(services.notifyError({ message: errorMessage }));
        } finally {
            setProgramLoading(false);
        }
      };

    const currentLang = localStorage.getItem("i18next") || "en";
    let quitafcard = require(`./../assets/Icon/icon-qitaf-${currentLang}.png`)
    const currentLanguageCode = localStorage.getItem("i18next") || "en";

    return (
        <>
            {pending && <NewLoader />}
            {cartDetail !== undefined && cartTotalData !== undefined &&
                <>
                    <div className="checkout__summary__block">
                        <div className="checkout__summary__title mb-3" >
                            <Heading6 text={t('Order_Summary')} />
                        </div>
                     
                        <ul className="m-0 p-0 w-100 list-unstyled">
                            {cartDetail?.items && cartDetail.items.map((item, index) => (
                                <React.Fragment key={`order_items_${index}`} ><OrderItems item={item} index={index} titleChange={titleChange} cartTotalData={cartTotalData} t={t} cartDetail={cartDetail} /></React.Fragment>
                            ))}
                         
                            {loyaltyEarnType && loyaltyEarnTypeId && loyaltyEarnType.filter((item, i) => item.program === loyaltyEarnTypeId).map((item, i) => {
                                let imageUrl = item.program === "meza" ? mezzacard : item.program === "niqaty" ? niqaty : quitafcard
                                return (
                                    <React.Fragment key={i}>
                                        <li className="d-flex  align-items-start w-100">
                                            <p className="m-0 p-0"><img src={imageUrl} /></p>
                                            <span className="sum__points d-block">+{`${item.points}`} {t("points")}</span>
                                        </li>
                                    </React.Fragment>
                                )
                            })}
                            <hr />
                            {
                                (isTamaraEnabled4 || tabbyEnabled) && (    
                                <div>
                                    {isTamaraEnabled4 && (
                                        <div className="product__offer__block" style={{marginTop: '10px',marginBottom: '10px'}}>
                                            <TamaraInstallmentPlanWidget
                                                months={4}
                                                price={
                                                cartData?.totals_data
                                                    ?.base_grand_total
                                                }
                                                currency={
                                                cartData?.totals_data
                                                    ?.base_currency_code
                                                }
                                                currentLanguageCode={currentLanguageCode}
                                                inlineType={2}
                                            />
                                        
                                        </div>
                                        
                                    )}
                                    {tabbyEnabled && (
                                        <div className="product__offer__block" style={{marginTop: '10px',marginBottom: '10px'}}>
                                            <TabbyBanner
                                                t={t}
                                                totalPrice={cartData?.totals_data?.base_grand_total}
                                                setShowTabby={() => setShowTabby(true)}
                                                currentLang={currentLanguageCode}
                                            />
                                        </div>
                                )}
                                </div>
                            )}
                            {cartTotalData?.shipping_amount > 0 && (
                                <li className="d-flex  align-items-start w-100">
                                    <p className="m-0 p-0">{t("Shipping")}</p>
                                    <Price
                                        price={cartTotalData.shipping_amount}
                                        size="heading7"
                                        currency={cartTotalData?.base_currency_code}
                                    />
                                </li>
                            )}
                            {codFee && codFee.value !== null && (
                                <li className="d-flex  align-items-start w-100">
                                    <p className="m-0 p-0">{t("Cash_on_delivery_fee")}</p>
                                    <Price
                                        price={codFee.value}
                                        size="heading7"
                                        currency={cartTotalData?.base_currency_code}
                                    />
                                </li>
                            )}
                            
                            {cartTotalData?.discount_amount < 0 && (
                                <li className="d-flex  align-items-start w-100 discount_info_row">                                    
                                    {loading &&
                                        <div className="discount_remove_spinner">
                                            <Spinner type="dark" />
                                        </div>
                                    }
                                    <div className="d-flex flex-row">
                                        <label className="m-0 p-0">{t("Discount")}</label>
                                        {
                                            !cartData?.totals_data?.extension_attributes?.is_bundle && (
                                                <button
                                                    className="btn btn-link p-0 ms-1"
                                                    type="button"
                                                    id="button-addon2"
                                                    onClick={() => removeCouponCode()}
                                                >
                                                    {t("remove")}
                                                </button>
                                            )}
                                    </div>
                                    <Price
                                        price={cartTotalData?.discount_amount - (-cartTotalData?.discount_amount * 0.15)}
                                        size="heading7"
                                        currency={cartTotalData?.base_currency_code}
                                    />
                                </li>
                            )}
                            <hr />
                            {programLoading &&
                                <div className="my-2">
                                    <Spinner type="dark" />
                                </div>
                            }
                            {meza && (
                                <li className="d-flex  align-items-start w-100">
                                    <p className="m-0 p-0">{t("Discount_(Meza)")}
                                        {meza.extension_attributes.action.removable && <button className="btn btn-link" onClick={() => removeLoyaltyHandler('meza')}>{t("remove")}</button>}
                                    </p>
                                    <Price
                                        color="#22D400"
                                        price={meza.value}
                                        size="heading7"
                                        currency={cartTotalData?.base_currency_code}
                                    />
                                </li>
                            )}
                            {qitaf && (
                                <li className="d-flex  align-items-start w-100">
                                    <p className="m-0 p-0">{t("Discount_(Qitaf)")}
                                        {qitaf.extension_attributes.action.removable && <button className="btn btn-link" onClick={() => removeLoyaltyHandler('qitaf')}>{t("remove")}</button>}
                                    </p>
                                    <Price
                                        color="#22D400"
                                        price={qitaf.value}
                                        size="heading7"
                                        currency={cartTotalData?.base_currency_code}
                                    />
                                </li>
                            )}
                            {mokafa && (
                                <li className="d-flex  align-items-start w-100">
                                    <p className="m-0 p-0">{t("Discount (Mokafa)")}
                                        <button className="btn btn-link" onClick={() => removeMokafa()}>{t("remove")}</button>
                                    </p>
                                    <Price
                                        color="#22D400"
                                        price={mokafa.value}
                                        size="heading7"
                                        currency={cartTotalData?.base_currency_code}
                                    />
                                </li>
                            )}
                            {niqatyLoyalty && (
                                <li className="d-flex  align-items-start w-100">
                                    <p className="m-0 p-0">{t("Discount (Niqaty)")}
                                        <button className="btn btn-link" onClick={() => removeNiqaty()}>{t("remove")}</button>
                                    </p>
                                    <Price
                                        color="#22D400"
                                        price={niqatyLoyalty?.value}
                                        size="heading7"
                                        currency={cartTotalData?.base_currency_code}
                                    />
                                </li>
                            )}
                            <LoyaltyPointBlock
                                loyaltyEarnType={loyaltyEarnType}
                                loyaltyEarnTypeId={loyaltyEarnTypeId}
                                currency={cartTotalData?.base_currency_code}
                                loyaltyRadioName={loyaltyRadioName}
                                cartDetail={cartDetail}
                            />
                            <hr />
                            {/* <li className="d-flex  align-items-start w-100">
                                <p className="m-0 p-0"><img src={mezzaimg} /></p>
                                <p>
                                    <span className="sum__points d-block">+1689 points</span>
                                    <span className="sum__eq">equals to SAR 24.00</span>
                                </p>
                            </li>
                            <hr /> */}                        

                            {subTotalData && (
                                <li className="d-flex  align-items-start w-100">
                                    <p className="m-0 p-0">{t("Total_(Without Tax)")}</p>
                                    <Price
                                        price={cartDetail?.totals_data?.subtotal_with_discount ? cartDetail?.totals_data?.subtotal_with_discount : subTotalData.value}
                                        size="heading7"
                                        currency={cartTotalData?.base_currency_code}
                                    />
                                </li>
                            )}
                            {taxData && (
                                <>
                                    <li className="d-flex  align-items-start w-100">
                                        <p className="m-0 p-0">{t("Tax")}</p>
                                        <Price
                                            price={taxData.value}
                                            size="heading7"
                                            currency={cartTotalData?.base_currency_code}
                                        />
                                    </li>
                                    <hr />
                                </>
                            )}
                            {cartDetail.totals_data.total_segments.find((x) => x.code === 'bss_storecredit') &&
                                <li className="d-flex align-items-start w-100 summary_cashback ">
                                    <p className="m-0 p-0">{t("Store Credit")}</p>
                                    <Price
                                        price={cartDetail?.totals_data.total_segments.find((x) => x.code === 'bss_storecredit').value}
                                        size="heading7"
                                        currency={cartTotalData?.base_currency_code}
                                    />
                                </li>}
                            <li className="d-flex  align-items-start w-100">
                                <p className="m-0 p-0"><strong>{t("Total")}</strong></p>
                                <Price
                                    price={cartTotalData?.base_grand_total}
                                    size="heading6"
                                    currency={cartTotalData?.base_currency_code}
                                />
                            </li>
                            <hr />
                        </ul>
                  
                        {
                            !isBundleExist && (  
                                <div className="checkout__coupon__block mt-2">
                                    <p><strong>{t("Coupon_code_or_gift_card")}</strong></p>
                                    <div className="cart_coupon_code_inputbox">
                                        {coupenLoading &&
                                            <div className="cart_coupon_spinnerbox">
                                                <Spinner type="dark" />
                                            </div>
                                        }
                                        <div className="input-group d-inline-block me-1">
                                            <input type="text" value={coupenCode} className="form-control w-100" placeholder={t('Enter_Code')} onChange={handleChange} />
                                            <button className="" type="button" id="button-addon2" onClick={() => handleApplyCode()}>{t("Apply")}</button>
                                        </div>
                                        {coupenCode !== "" &&
                                            <div onClick={(e) => setCoupenCode('')} className="d-inline-block remove_coupen_code_btn">
                                                <img src={closeicon} alt="close" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}                        
                    </div>
                    <div className="checkout__bottom__text">
                        <p>
                            {t("Our_checkout_is_safe_and_secure")}
                        </p>
                    </div>
                </>
            }
            {showTabby && (
                <TabbyInformation
                    currentLang={currentLanguageCode}
                    tabbyPrice={cartData?.totals_data?.base_grand_total}
                    onClose={() => setShowTabby(false)}
                    t={t}
                    />
            )}
        </>
    );
}
export default Order_Summary_New;
