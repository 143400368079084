/* eslint-disable radix */
import React from 'react';
import styles from '../../SCSS/components/Tabby/TabbyPaymentMethod.module.scss';
import clsx from 'clsx';
import tabbyIcon from '../../assets/Icon/credit-card@2x.png';
import CurrencyDisplay from '../Cards/CurrencyDisplay';
import { getInstallments } from '../../lib/tabby';

const TabbyPaymentMethod = ({ price, t, currentLang }) => {
  const installments = getInstallments(price, null, currentLang);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabby}>
        <img src={tabbyIcon} alt="tabby" width={48} height={48} />
        <span>
          {t(
            'tabby_payment_intro',
            'After clicking "complete order", you will be redirected to tabby - pay in 4 Easy Monthly Installments to complete your purchase securely.'
          )}
        </span>
      </div>
      <div className={styles.breakdown}>
        <div className={styles.installment}>
          <div className={styles.scheduledDay}>
            <div className={styles.dot} />
            <span className={styles.yellowText}>
              {t('tabby_paymentmethod_todayspayment', "Today's Payment")}
            </span>
          </div>
          <div
            className={clsx(
              'currency_display_block header-active',
              styles.currencyToday
            )}
          >
            <span>{t('SAR')}</span> &nbsp;
            <span>{`${parseFloat(installments.downpayment.price).toFixed(
              2
            )}`}</span>
          </div>
        </div>
        <div className={styles.dotEmptyLine} />
        <div className={styles.dotEmptyLine} />
        <div className={styles.dotEmptyLine} />
        <div className={styles.installment}>
          <div className={styles.scheduledDay}>
            <div className={styles.dottedBorder} />
            <span>{installments.installmentTwo.date}</span>
          </div>
          <CurrencyDisplay
            currentLang={currentLang}
            price={installments.installmentTwo.price}
            t={t}
            className={styles.currency}
          />
        </div>
        <div className={clsx(styles.dotEmptyLine, styles.future)} />
        <div className={clsx(styles.dotEmptyLine, styles.future)} />
        <div className={clsx(styles.dotEmptyLine, styles.future)} />
        <div className={styles.installment}>
          <div className={styles.scheduledDay}>
            <div className={styles.dottedBorder} />
            <span>{installments.installmentThree.date}</span>
          </div>
          <CurrencyDisplay
            currentLang={currentLang}
            price={installments.installmentThree.price}
            t={t}
            className={styles.currency}
          />
        </div>
        <div className={clsx(styles.dotEmptyLine, styles.future)} />
        <div className={clsx(styles.dotEmptyLine, styles.future)} />
        <div className={clsx(styles.dotEmptyLine, styles.future)} />
        <div className={styles.installment}>
          <div className={styles.scheduledDay}>
            <div className={styles.dottedBorder} />
            <span>{installments.installmentFour.date}</span>
          </div>
          <CurrencyDisplay
            currentLang={currentLang}
            price={installments.installmentFour.price}
            t={t}
            className={styles.currency}
          />
        </div>
      </div>
    </div>
  );
};

export default TabbyPaymentMethod;
