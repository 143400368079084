import React, { useEffect, useRef } from 'react';
import useLockBodyScroll from '../../hooks/use-lock-body-scroll';
import styles from '../../SCSS/components/Tabby/TabbyInformation.module.scss';
import clsx from 'clsx';
import CurrencyDisplay from '../Cards/CurrencyDisplay';
import { chartOptions, getInstallments } from '../../lib/tabby';
import Chart from 'react-google-charts';
import closeicon from '../../assets/Icon/cancel_grey.svg';
import tabbyIcon from '../../assets/Icon/tabby-badge.png';
import { paymentCardConfig } from '../../assetsConfig/footer';

const TABBY = {
  THRESHOLDS: [
    { min: 0, max: 3000, downpayment: 25 },
    { min: 3001, max: 5000, downpayment: 35 },
    { min: 5001, max: 7000, downpayment: 50 },
  ],
};

const TabbyInformation = ({ onClose, t, tabbyPrice, currentLang, product }) => {
  useLockBodyScroll();
  const ref = useRef(null);

  const installments = getInstallments(tabbyPrice, null, currentLang);

  // calculate applicable tabby threshold
  let thresholdDownpayment = TABBY.THRESHOLDS[0];
  TABBY.THRESHOLDS.forEach((threshold) => {
    if (threshold.min <= tabbyPrice && threshold.max >= tabbyPrice) {
      thresholdDownpayment = threshold;
    }
  });
  const installment = (100 - thresholdDownpayment.downpayment) / 3;

  const percent25 = [
    ['', ''],
    ['Downpayment', thresholdDownpayment.downpayment],
    ['Installments', 100 - thresholdDownpayment.downpayment],
  ];
  const percent50 = [
    ['', ''],
    ['Downpayment', thresholdDownpayment.downpayment + installment],
    ['Installments', 100 - (thresholdDownpayment.downpayment + installment)],
  ];
  const percent75 = [
    ['', ''],
    ['Downpayment', thresholdDownpayment.downpayment + installment * 2],
    [
      'Installments',
      100 - (thresholdDownpayment.downpayment + installment * 2),
    ],
  ];
  const percent100 = [
    ['', ''],
    ['Total', 100],
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose && onClose();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClose]);

  const renderCharts = () => (
    <div className={styles.charts}>
      <div className={styles.chart}>
        <Chart
          chartType="PieChart"
          data={percent25}
          options={chartOptions}
          width="70px"
          height="70px"
        />
        <span>{t('tabby_intromodal_chart_today', 'Today')}</span>
      </div>
      <div className={styles.chart}>
        <Chart
          chartType="PieChart"
          data={percent50}
          options={chartOptions}
          width="70px"
          height="70px"
        />
        <span>{t('tabby_intromodal_chart_monthone', '1st month')}</span>
      </div>
      <div className={styles.chart}>
        <Chart
          chartType="PieChart"
          data={percent75}
          options={chartOptions}
          width="70px"
          height="70px"
        />
        <span>{t('tabby_intromodal_chart_monthtwo', '2nd month')}</span>
      </div>
      <div className={styles.chart}>
        <Chart
          chartType="PieChart"
          data={percent100}
          options={chartOptions}
          width="70px"
          height="70px"
        />
        <span>{t('tabby_intromodal_chart_monththree', '3rd month')}</span>
      </div>
    </div>
  );

  const renderExample = (product) => {
    if (!product) {
      return;
    }
    return (
      <div className={styles.example}>
        <span>{product?.name}</span>
        <div className={styles.product}>
          <div className={styles.top}>
            <img className={styles.productImage} src={product?.media?.image?.screenshots[0]?.image || undefined} alt="tabby" width={40} height={45} />
            <div className={styles.totalPrice}>
              <CurrencyDisplay
                price={tabbyPrice}
                t={t}
                currentLang={currentLang}
              />
              <span>
                {t('tabby_intromodal_example_vat', '(Inclusive of VAT)')}
              </span>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.summary}>
              <div className={styles.installmentPrice}>
                {t(
                  'tabby_intromodal_example_instalmentone',
                  '1st Instalment of'
                )}
                {'\u00a0'}
                <div>{`${installments.downpayment.price} ${t('SAR')}`}</div>
              </div>
              <span>
                {t('{{ percent }}% downpayment for {{ min }}-{{ max }} SAR', {
                  percent: installments.downpayment.threshold.downpayment,
                  min: installments.downpayment.threshold.min,
                  max: installments.downpayment.threshold.max,
                })}
              </span>
            </div>
            <img src={tabbyIcon} alt="tabby" width={50} height={20} />
          </div>
        </div>
        <div className={styles.breakdown}>
          <div className={styles.installment}>
            <div className={styles.scheduledDay}>
              <div className={styles.dot} />
              <span className={styles.yellowText}>
                {t('tabby_intromodal_todayspayment', 'Today’s Payment')}
              </span>
            </div>
            <CurrencyDisplay
              currency="currency_short"
              price={installments.downpayment.price}
              t={t}
              currentLang={currentLang}
            />
          </div>
          <div className={styles.dotEmptyLine} />
          <div className={styles.dotEmptyLine} />
          <div className={styles.dotEmptyLine} />
          <div className={styles.installment}>
            <div className={styles.scheduledDay}>
              <div className={styles.dottedBorder} />
              <span>{installments.installmentTwo.date}</span>
            </div>
            <CurrencyDisplay
              currency="currency_short"
              price={installments.installmentTwo.price}
              t={t}
              currentLang={currentLang}
            />
          </div>
          <div className={clsx(styles.dotEmptyLine, styles.future)} />
          <div className={clsx(styles.dotEmptyLine, styles.future)} />
          <div className={clsx(styles.dotEmptyLine, styles.future)} />
          <div className={styles.installment}>
            <div className={styles.scheduledDay}>
              <div className={styles.dottedBorder} />
              <span>{installments.installmentThree.date}</span>
            </div>
            <CurrencyDisplay
              currency="currency_short"
              price={installments.installmentThree.price}
              t={t}
              currentLang={currentLang}
            />
          </div>
          <div className={clsx(styles.dotEmptyLine, styles.future)} />
          <div className={clsx(styles.dotEmptyLine, styles.future)} />
          <div className={clsx(styles.dotEmptyLine, styles.future)} />
          <div className={styles.installment}>
            <div className={styles.scheduledDay}>
              <div className={styles.dottedBorder} />
              <span>{installments.installmentFour.date}</span>
            </div>
            <CurrencyDisplay
              price={installments.installmentFour.price}
              t={t}
              currentLang={currentLang}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => (
    <div className={styles.footer}>
      <span>
        {t('tabby_intromodal_footer_text', 'Use any card with Tabby.')}
      </span>
      <div className={styles.payments}>
        {paymentCardConfig.map(
          ({ iconPath, alt, externalUrl }, socialIndex) => {
            return (
              <React.Fragment key={alt}>
                {externalUrl !== '' ? (
                  <a href={externalUrl} target="_blank" rel="noreferrer">
                    <img
                      key={alt}
                      src={require(`./../../${iconPath}`)}
                      alt={alt}
                      className="card"
                      width={30}
                      height="auto"
                    />
                  </a>
                ) : (
                  <img
                    key={alt}
                    src={require(`./../../${iconPath}`)}
                    alt={alt}
                    className="card"
                    width={30}
                    height="auto"
                  />
                )}
              </React.Fragment>
            );
          }
        )}
      </div>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.inner} fadeInTop`}>
        <div className={styles.header}>
          <img src={tabbyIcon} alt="tabby" width={65} height={30} />
          <div className={styles.title}>
            {t('tabby_intromodal_title', 'Buy now, Pay in installments')}
          </div>
          <button
            type="button"
            className="btn btn-icon"
            onClick={onClose}
            tabIndex={-1}
          >
            <img src={closeicon} alt="close" />
          </button>
        </div>
        <div className={styles.sectionWrapper}>
          <span>
            {t('tabby_intromodal_subheading', '4 interest-free payments')}
          </span>
          <p>
            {t(
              'tabby_intromodal_charts',
              'Shop now and pay later by choosing tabby at checkout to split your purchases into 4 interest-free payments.'
            )}
          </p>
          {renderCharts()}
          {renderExample(product)}
        </div>
        <div className={styles.sectionWrapper}>
          <span className={styles.yellowText}>
            {t('tabby_intromodal_breakdown_title', 'Payment Breakdown:')}
          </span>
          <div>
            <table>
              <tbody>
                <tr>
                  <th>{t('tabby_intromodal_breakdown_amount', 'Amount')}</th>
                  <th>
                    {t('tabby_intromodal_breakdown_downpayment', 'Downpayment')}
                  </th>
                </tr>
                {TABBY.THRESHOLDS.map((threshold, index) => (
                  <tr key={`tabby_${index}`}>
                    <td>{`${threshold.min} - ${threshold.max} ${t('SAR')}`}</td>
                    <td>{`${threshold.downpayment}%`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <p>
            {t(
              'tabby_intromodal_breakdown_note',
              '***Instalment payment is breakdown according to the amount of the product. e.g if the product is in between 10-3000 SAR the first downpayment will be 25% and rest instalment payment is equally paid during the next 3 months'
            )}
          </p>
        </div>
        <div className={styles.sectionWrapper}>
          <span className={styles.yellowText}>
            {t(
              'tabby_intromodal_title_nointerestorfees',
              'No interest or fees.'
            )}
          </span>
          <p>
            {t(
              'tabby_intromodal_nointerestorfees_body',
              "Built on trust. Not interest. You'll never pay extra when you pay on time."
            )}
          </p>
        </div>
        <div className={styles.bottomWrapper}>
          <div className={styles.howItWorks}>
            <span className={styles.yellowText}>
              {t('tabby_intromodal_title_howitworks', 'How it works:')}
            </span>
            <p className={styles.noteOne}>
              <div>
                {t('tabby_intromodal_howitworks_note1_section1', '1- Choose')}
              </div>
              <img src={tabbyIcon} alt="tabby" width={41} height={16} />
              <div>
                {t(
                  'tabby_intromodal_howitworks_note1_section2',
                  'at checkout.'
                )}
              </div>
            </p>
            <p>
              {t(
                'tabby_intromodal_howitworks_note2',
                '2- Enter your information and add your debit or credit card.'
              )}
            </p>
            <p>
              {t(
                'tabby_intromodal_howitworks_note3',
                '3- Split your purchase in 4 monthly payments.'
              )}
            </p>
            <p>
              {t(
                'tabby_intromodal_howitworks_note4',
                '4- We’ll send sms reminders when your next payment is due.'
              )}
            </p>
          </div>
        </div>
        {renderFooter()}
      </div>
    </div>
  );
};

export default TabbyInformation;
