const TABBY = {
  THRESHOLDS: [
    { min: 0, max: 3000, downpayment: 25 },
    { min: 3001, max: 5000, downpayment: 35 },
    { min: 5001, max: 7000, downpayment: 50 },
  ],
};

const addMonths = (date, months) => {
  const d = new Date(date);
  d.setMonth(d.getMonth() + months);
  return d;
};

export const getInstallments = (price, date, lang = 'en') => {
  const today = new Date();
  const options = {
    month: 'long',
    day: '2-digit',
  };

  let thresholdDownpayment = TABBY.THRESHOLDS[0];
  TABBY.THRESHOLDS.forEach((threshold) => {
    if (threshold.min <= price && threshold.max >= price) {
      thresholdDownpayment = threshold;
    }
  });

  const downpaymentDate = Intl.DateTimeFormat(lang, options).format(today);
  const downpaymentPrice = (price * thresholdDownpayment.downpayment) / 100;
  const installmentPrice = (price - downpaymentPrice) / 3;

  const installmentDateTwo = Intl.DateTimeFormat(lang, options).format(addMonths(today, 1));
  const installmentDateThree = Intl.DateTimeFormat(lang, options).format(addMonths(today, 2));
  const installmentDateFour = Intl.DateTimeFormat(lang, options).format(addMonths(today, 3));

  return {
    downpayment: {
      date: downpaymentDate,
      price: downpaymentPrice,
      threshold: thresholdDownpayment,
    },
    installmentTwo: {
      date: installmentDateTwo,
      price: installmentPrice,
    },
    installmentThree: {
      date: installmentDateThree,
      price: installmentPrice,
    },
    installmentFour: {
      date: installmentDateFour,
      price: installmentPrice,
    },
  };
};

export const chartOptions = {
  pieSliceText: 'none',
  colors: ['#fec602', '#27272a'],
  legend: { position: 'none' },
  enableInteractivity: false,
  pieSliceBorderColor: '#000',
  backgroundColor: {
    fill: '#FFF',
    fillOpacity: 11,
    color: '#27272a',
  },
  is3D: true,
};
