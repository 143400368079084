/* eslint-disable radix */
import React from 'react';
import styles from '../../SCSS/components/Tabby/TabbyBanner.module.scss';
import { getInstallments } from '../../lib/tabby';
import tabbybadge from "./../../assets/Icon/tabby-badge.png";

const TabbyBanner = ({
  t, totalPrice, setShowTabby, currentLang,
}) => {
  const installments = getInstallments(totalPrice, null, currentLang);
  return (
    <div className={styles.wrapper} onClick={() => setShowTabby(true)}>
      <div className={styles.textWrapper}>
        {t('or 4 payments of')}
        {' '}
        <b>
          {t('SAR')}
          {' '}
          {installments.downpayment.price}
        </b>
        .
        {t('No interest, no fees.')}
        {' '}
        <span>
          <u>{t('Learn-More')}</u>
        </span>
      </div>
      <img
        height={24}
        width={60}
        src={tabbybadge}
        loading="lazy"
        alt="tabby"
      />
    </div>
  );
};

export default TabbyBanner;
