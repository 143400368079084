import { useLayoutEffect, useEffect } from 'react';

const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    // TODO fix why eslint is giving error there
    // eslint-disable-next-line
    return () => document.body.style.overflow = originalStyle;
  }, []);
};

export const useBodyScrollMechanism = () => {
  // eslint-disable-next-line
  useEffect(() => () => document.body.style.overflow = 'hidden auto', []);
  return ({
    // eslint-disable-next-line
    lock: () => (document.body.style.overflow = 'hidden'),
    // eslint-disable-next-line
    release: () => (document.body.style.overflow = 'hidden auto'),
  });
};

export default useLockBodyScroll;
